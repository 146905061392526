import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import SEO from "../components/Seo";
import Mikan from "mikanjs";
import { Helmet } from "react-helmet";
import { Container, Box, Image, Button, Lead, T2, T3 } from "../styles/Styles";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import ch1 from "../img/valid-chart-1.png";
import ch2 from "../img/valid-chart-2.png";
import ch3 from "../img/valid-chart-3.png";
import ch4 from "../img/valid-chart-4.png";
import ch5 from "../img/valid-chart-5.png";
import ch6 from "../img/valid-chart-6.png";

const scientists = [
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/02/Yuval-Gonczarowski-ClimaCell-260x300.png",
    name: "Yuval Gonczarowski",
    position: "Chief Technology Officer",
    about:
      "Yuval has over a decade of experience in prototyping and introducing new technologies. He served as a Captain in the IDF\u2019s elite signal intelligence unit, 8200, leading innovative teams and projects, deployed rapid prototyping devices for Intel (high-speed networking) and Apple (Storage devices and the Apple Watch), and advised numerous tech companies on Digital and Technology transformation with McKinsey and Company. Yuval holds a B.Sc. (magna cum laude) In Electrical Engineering from Technion \u2013 Israel Institute of Technology, and an MBA (with distinction) from Harvard Business School."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/05/luke-peffers.png",
    name: "Luke Peffers, PhD",
    position: "SVP, Head of NWP Division",
    about:
      "Dr. Peffers brings over 18 years of experience as an active-duty service member, a civil servant for the USAF, the Chief Scientists for a DoD contractor, an adjunct professor of Meteorology for Embry Riddle Aeronautical University's Worldwide Campus and now as SVP of tomorrow.io's Numerical Weather Prediction (NWP) division. Dr. Peffers earned his Ph.D. in Atmospheric Science in collaboration with the U.S. Air Force Technical Applications Center (AFTAC). After earning his Ph.D., Dr. Peffers worked at AFTAC and became the branch chief of the Meteorological Modeling and Analysis team that is responsible for designing and operating weather modeling systems that are used to monitor compliance with multiple global nuclear test ban treaties. Dr. Peffers then joined STAR, LLC to serve as Chief Scientist where he managed numerous DoD contracts and projects involving the development and sustainment of operational weather models and weather-driven Chemical, Biological, Radiological, and Nuclear (CBRN) forensic analysis and forecasting systems for various agencies within the DoD and international governments."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/02/Daniel-Rothenberg-ClimaCell-260x300.png",
    name: "Daniel Rothenberg, PhD",
    position: "Chief Scientist",
    about:
      "Before joining tomorrow.io, Daniel was a scientist at the Massachusetts Institute of Technology, where he developed novel modeling tools. As a leader in his field, he organized and chaired scientific conferences and helped to found new initiatives in the geosciences aimed at building high-performance, open-source toolkits for working massive climate datasets. Before his PhD, he completed a BS in atmospheric science at Cornell University. Daniel passionately advocates for using \u201cbig data\u201d in all domains \u2014 especially weather and climate \u2014 to better inform decision-making and to protect lives and property."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/12/iman-gohari.png",
    name: "S. M. Iman Gohari, PhD",
    position: "NWP Operations Team Lead",
    about: `Dr. Gohari conducts independent research and development of applications for weather forecasting and real-time weather analysis. Dr. Gohari main focus is on performing Research to Operations (R2O) by building numerical weather prediction (NWP) prototypes in different computational platforms and to support those capabilities into operations. Dr. Gohari main expertises include: weather research and forecasting (WRF) model, high performance computing (HPC), Large Eddy Simulations (LES) and data science. He holds a Ph. D in computational atmospheric science from the University of California at San Diego.`
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/02/laura-260x300.png",
    name: "Laura Mariano",
    position: "New Technologies Team Lead",
    about:
      "Laura is a data scientist with ten years of experience developing signal processing and machine learning algorithms across a wide range of domains. Before joining tomorrow.io, she was a member of the technical staff at the Charles Stark Draper Laboratory, Inc., where she worked on diverse range of projects, including behavioral fingerprint identification, multispectral iris recognition, image processing for disease diagnostics, RF signal processing, and brain computer interfaces.\r\n"
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/12/amir-givati-hex.png",
    name: "Amir Givati, PhD",
    position: "Director of Flood Modeling",
    about:
      "Dr. Givati was the head of the Surface water and Hydrometeorology Department at the Israeli Hydrological Service under the Water Authority for 15 years. He was also the head of the research unit at the Water Authority. Dr. Givati received his Ph.D. from the Department of Atmospheric Sciences at the Institute of Earth Science, Hebrew University of Jerusalem. His expertise is Hydrometeorology, flood forecasting, Urban flooding, coupled meteorological-earth models, water resources, drought prediction, seasonal precipitation forecasting, regional climate modeling, climate change effects on the hydrological cycle. Dr. Givati is also a lecture at the Porter school for environmental studies at Tel Aviv University on climate, Hydrology and water."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/Jason-Lynn-ClimaCell-260x300.png",
    name: "Jason Lynn",
    position: "VP of Meteorology Strategy",
    about:
      "Jason has over 16 years of experience in operational meteorology, covering from now to next season, meteorological product development, and forecast technique development. He was the Senior VP Forecast Services at Weather Decision Technologies and the former Director of Meteorology at multiple commodities trading firms. Prior to that, he was an early career contributor to several research projects and tech-transfer initiatives from the National Severe Storms Laboratory and Storm Prediction Center. Jason earned his B.S. in Meteorology from the University of Oklahoma and his M.S. in Computer Science and Meteorology from the Human-Technology Interaction Center, University of Oklahoma."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/12/jonathan-eliashiv.png",
    name: "Jonathan Eliashiv, PhD",
    position: "Associate Scientist, MET Research Team",
    about:
      "Jonathan has 5 years of experience as a climate research scientist studying data assimilated coupled climate" +
      " models using Python, C++, and NCL. As a member of tomorrow.io's Met team, he works to explore how to leverage satellite and ground data to improve weather model forecasts in providing real-time analysis of cloud cover, precipitation, and other quantities. He produces analysis using machine learning and physics corrective techniques in order to optimize accuracy of the ascribed fields. Prior to working with tomorrow.io, Jonathan studied at the Scripps Institution of Oceanography at the University of California, San Diego. Jonathan holds a Ph.D in Oceanography, a B.S. in Physics with a specialization in Computational Physics, and a B.S. in Earth Sciences from the University of California, San Diego."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/12/logan-karsten.png",
    name: "Logan Karsten",
    position: "Senior Atmospheric Scientist",
    about: "Logan has over eight years of professional experience in research and operational hydrologic prediction." +
      " Starting out of graduate school with a masters focusing on snow hydrology, Logan worked at the National Operational Hydrologic Prediction Center in Minnesota developing new operational land surface models in support of National Water Center operations. In 2015, Logan moved to the National Center for Atmospheric Research in Boulder, CO where he worked on the WRF-Hydro development team to support core National Water Model research development. This work included improving input atmospheric forcings that drive hydrologic prediction, developing automated calibration workflows for WRF-Hydro, implementing seasonal ensemble streamflow forecasting in support of water resource management in Colorado, and assisting with the development of a hyper-resolution version of WRF-Hydro. Logan's focus is on forecast systems development and migration of spatially distributed hydrologic modeling systems to operations. Logan has a B.S. and Masters in meteorology from Iowa State University with a general business minor"
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/Ray-2-259x300.png",
    name: "Ruixiong (Ray) Zhang, PhD",
    position: "Atmospheric Data Scientist",
    about:
      "Ray is a scientist with over 9 years of research experience in atmospheric sciences. His expertise lies in statistical approaches and numerical models of weather and air pollution. He also worked with field measurement and satellite retrieval of trace gases. Before joining the team, he earned his PhD in atmospheric chemistry and worked as a research scientist at the Georgia Institute of Technology. Ray completed a BS in atmospheric science at Peking University (Beijing, China). "
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/Yu-1-259x300.png",
    name: "Yu Cheng, PhD",
    position: "Atmospheric Data Scientist",
    about:
      "Yu has eight years of experience using pioneering high-resolution climate models to study the ocean and its role in the climate system. His works have been published in the Journal of Climate and Ocean Modeling. Coming from Taiwan, he is a big fan of all weather phenomenon and the latest technology. He earned his B.S. in atmospheric science from National Taiwan University and his Ph.D. in Meteorology and Physical Oceanography from the University of Miami in 2018."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/02/img443-260x300.png",
    name: "Tamar Melman, PhD",
    position: "Data Scientist",
    about:
      "Tamar is a data scientist on the New Technologies team. She has over 10 years of experience in academic research, applying quantitative techniques to address questions related to human health. Most recently she was an Insight Data Science fellow, where she predicted patients' disease states based on biomechanical time series data. She did her PhD. in computational biology at Weill Cornell Graduate School, where she developed statistical techniques for reducing the effect of noise on analysis of brain wave signals. She earned her B.S. from Carnegie Mellon University, also in computational biology."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/12/max-marchand.png",
    name: "Max Marchand, PhD",
    position: "Project Scientist, Numerical Weather Prediction",
    about:
      "Dr. Marchand works on tomorrow.io’s Bespoke Atmospheric Model (CBAM), testing and implementing methods for" +
      " assimilating a diverse set of observations into the model. Before joining tomorrow.io, he earned his M.S. and Ph.D. at FSU researching methods for assimilating lightning and geostationary satellite information into high-resolution numerical weather models, and continued similar work as a postdoctoral fellow at CIRA/CSU."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/Chris-Hovanic-ClimaCell-260x300.png",
    name: "Chris Hovanic, CCM",
    position: "Customer Success Meteorologist",
    about:
      "Chris Hovanic followed a lifelong passion for weather into the field of meteorology, and has now worked" +
      " in the field of operational weather forecasting for 11 years, and in positions related to meteorology for nearly 13 years. Chris has worked all across the United States, ranging from the East Coast to the central/northern Great Plains, even spending the last 5 years in Salt Lake City, UT before heading back east to join tomorrow.io here in Boston. During his time in the Great Plains, Chris was an avid storm chaser and observed numerous tornadoes and supercell thunderstorms over the years. After several years of work, Chris earned the Certified Consulting Meteorologist (CCM) distinction from the American Meteorological Society (AMS) in 2016, and maintains the certification to this day."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/Kyle-Larkin-ClimaCell-260x300.png",
    name: "Kyle Larkin",
    position: "Customer Success Meteorologist",
    about:
      "Kyle started forecasting in the US Navy. There, he deployed as Forecast Duty Officer on board the USS Dwight D. Eisenhower, a nuclear-powered aircraft carrier based out of Norfolk, VA, where he provided environmental intelligence to help maximize performance of aircraft, ships, and sensor platforms. He has since gained extensive global forecasting experience in the private sector, assisting clients from around the world with a diverse range of operational weather needs. He earned his B.S. in Atmospheric Science from Lyndon State College."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/Reece-Todd-ClimaCell-Micro-Weather.-Global-Coverage-260x300.png",
    name: "Reece Todd",
    position: "Customer Success Meteorologist",
    about:
      "Reece joined tomorrow.io in 2018, previously having over eight years of operational weather forecasting experience. His passion for weather has taken him from the Mid-Atlantic, to the Great Plains, and the Intermountain West - where he spent the past 4 \u00bd years forecasting in Salt Lake City, UT. At a young age, he developed a keen interest in East Coast winter storms, as well as Atlantic Hurricane seasons. Throughout his career, he has refined his knowledge of those subjects; and has also gained years of experience in forecasting severe weather across the United States, as well as understanding and identifying mesoscale meteorological patterns over complex terrain. Reece earned a B.S. in Earth Sciences-Meteorology from California University of Pennsylvania, as well as a B.S. in Geography and Geosciences from Salisbury University."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/03/Micheal-Huguet-ClimaCell-Micro-Weather.-Global-Coverage-260x300.png",
    name: "Micheal Huguet",
    position: "Customer Success Meteorologist",
    about:
      "Micheal is a meteorologist who prides himself on being adaptable to a wide variety of forecasting roles. He earned his B.S. in Meteorology at Valparaiso University in 2007 and started his career in marine forecasting and data quality at the National Data Buoy Center, then got some small business experience as an environmental consultant in Houston, before getting his big business experience and aviation expertise as an aviation meteorologist at The Weather Company, an IBM business."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/10/jim-bishop-519x600.png",
    name: "Jim Bishop",
    position: "Customer Success Meteorologist",
    about:
      "Jim spent fifteen years as the lead meteorologist for multiple energy producers and hedge funds across the U.S. From the short term to medium and long range, his comprehensive forecasting experience spans the globe and covers all facets of weather \u2013 from temperatures to wind at the wind farm level, to hurricanes, nor\u2019easters to severe weather and everything in between. While attending the University of Oklahoma, Jim began storm chasing, applying his knowledge from the classroom to forecast tornadoes. He continued storm chasing after receiving his Bachelor of Science in Meteorology at OU, and over the last twenty years has documented some of the largest and most violent tornadoes on record. Jim\u2019s interviews and tornado footage have been recognized worldwide on countless TV networks. While attending OU and throughout the last twenty years, Jim has spoken at numerous community and university events discussing severe weather and tornado safety, spreading severe weather awareness and sharing his unique storm chasing experiences with the public."
  },
  {
    image:
      "https://cms2.climacell.co/wp-content/uploads/2019/02/Alan-Brammer-PhD-ClimaCell-260x300.png",
    name: "Alan Brammer, PhD",
    position: "Consultant",
    about:
      "Alan has five years of experience researching tropical convection and developing multi-variable tracking algorithms for Atlantic hurricanes. He worked with real-time ensemble forecasts and developed concise methods of displaying probabilistic forecasts as well as on field campaigns, forecasting for unmanned high-altitude drone flights over hurricanes. He earned his BSc from Leeds University (UK) and his PhD from the University at Albany in 2015.\r\n"
  }
];

const charts = [ch1, ch2, ch3, ch4, ch5, ch6];

export default ({ data, location }) => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex, nofollow"/>
    </Helmet>
    <SEO
      title="科学的リーダシップチーム | tomorrow.io"
      description="tomorrow.io’s MicroWeather science team of meteorologists, scientists, and PhDs has created tremendous innovations in the weather industry. Read more!"
      image={`https://www.tomorrow.io${data.hero.childImageSharp.fluid.src}`}
      pathname={location.pathname}
    />

    <Container>

      <Box display="flex" alignItems="center" flexWrap="wrap" py={5}>
        <Box width={[1, 1 / 2]}>
          <T2 as="h1">
            科学的リーダシップチーム
          </T2>
          <Lead mb={4} dangerouslySetInnerHTML={{__html: Mikan(`tomorrow.ioのMicroWeather科学チームは、何十年以上にもわたり、気象産業において最大といえるイノベーションに携わってきました。チームは気象士、アナリスト、大気科学のPhD（博士号取得者）、風力エネルギー、電気工学、その他の分野おける専門家で構成されています。このエキスパートの集まりが、tomorrow.ioにおける全ての中核を担っています。`)}}/>
        </Box>
        <Box width={[2 / 3, 3 / 10]} mx="auto">
          <Img fluid={data.hero.childImageSharp.fluid}
               alt="MicroWeather API"
          />
        </Box>
      </Box>

      <Box py={5} textAlign="center">
        <Box display="flex" flexWrap="wrap">
          {scientists.map((person, index) => (
            <Box width={[1, 1 / 2, 1 / 5]} key={index}>
              <Box className="fs-sm pointer text-center" id={`about-${index}`}>
                <LazyLoad height={220} once>
                  <Image width={160} mb={3} src={person.image}
                         style={{ filter: `drop-shadow(0 1px 3px rgba(0,0,0,0.1)` }}
                         alt=""/>
                </LazyLoad>
                <Box mb={0} fontWeight={800} dangerouslySetInnerHTML={{ __html: person.name }}/>
                <p mb={4} dangerouslySetInnerHTML={{ __html: person.position }}/>
              </Box>
              <UncontrolledPopover
                trigger="legacy"
                placement="bottom"
                target={`about-${index}`}
              >
                <PopoverBody
                  dangerouslySetInnerHTML={{ __html: person.about }}
                />
              </UncontrolledPopover>
            </Box>
          ))}
        </Box>
      </Box>

      <Box py={5} textAlign="center">
        <T3>tomorrow.ioの検証演習</T3>
        <Lead px={[0, 6]} mb={5}>
        tomorrow.ioでは、検証演習を構築することを第一優先に、弊社の気象データを常時モニターし、<br/>提供するソリューションの向上に努めます。
        </Lead>
        <LazyLoad height={1400} once>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {charts.map(chart => (
              <Box width={[1, 1, 1 / 2]} textAlign="center" mb={5} key={chart} px={[1, 5]}>
                <Image src={chart} width="100%" maxHeight={350} maxWidth={500} style={{ objectFit: "contain" }} alt=""/>
              </Box>
            ))}
          </Box>
        </LazyLoad>
      </Box>

      <Box display='flex' justifyContent="center" flexWrap="wrap" pb={6} mx="auto">
        <Box width={[1, 1 / 2, "370px"]} px={[0, 3]} mb={3}>
          <Button as="a" display="block" href="https://Tomorrow.io/careers/">
            参加
          </Button>
        </Box>
        <Box width={[1, 1 / 2, "370px"]} px={[0, 3]} mb={3}>
          <Button display="block" outline={1} as="a" style={{ whiteSpace: "normal" }} href="https://Tomorrow.io/request-validation/">
            すべての検証報告書を請求
          </Button>
        </Box>
      </Box>

    </Container>
  </Layout>
);

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "feature-engine.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;